package ui.search

import androidx.compose.foundation.border
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.aspectRatio
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.semantics.clearAndSetSemantics
import androidx.compose.ui.semantics.contentDescription
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import formatDecimalToCurrency
import formatToPercentage
import model.MerchantSummaryInformation
import receipt.OfferReceiveTypes
import removeTrailingZerosAndTruncateToTwoDecimalPositionsIfAny
import toContentDescription
import ui.Colors

@Composable
fun DailyOffer(
    store: MerchantSummaryInformation,
    defaultColor: Color = Colors.MerchantsNearMeGreen,
) {
    val dailyOffer = store.dailyOffer
    val displayMessage: String = dailyOffer?.message ?: ""
    val displayValue: String = when (dailyOffer?.receivedType) {
        OfferReceiveTypes.ReceiveFixedAmount.offerReceiveTypes -> {
            dailyOffer
                .receivedAmount
                ?.toDouble()
                ?.formatDecimalToCurrency()
                ?.removeTrailingZerosAndTruncateToTwoDecimalPositionsIfAny()
                ?: "0".toDouble().formatDecimalToCurrency()
        }

        OfferReceiveTypes.ReceivePercentage.offerReceiveTypes -> {
            dailyOffer
                .percentageReceived
                ?.formatToPercentage() ?: ""
        }

        OfferReceiveTypes.Point.offerReceiveTypes -> {
            dailyOffer
                .pointReceived
                ?.toDouble()
                ?.formatDecimalToCurrency()
                ?.removeTrailingZerosAndTruncateToTwoDecimalPositionsIfAny()
                ?: ""
        }

        else -> {
            ""
        }
    }

    Box(
        modifier = Modifier
            .clearAndSetSemantics {
                contentDescription = toContentDescription(
                    displayValue,
                    displayMessage
                )
            }
            .padding(16.dp)
            .aspectRatio(1f)
            .border(
                width = 1.dp,
                color = defaultColor,
                shape = RoundedCornerShape(12.dp)
            )
    ) {
        Column(
            modifier = Modifier.fillMaxSize().padding(8.dp),
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.SpaceEvenly
        ) {
            Text(
                displayValue,
                fontWeight = FontWeight.Bold,
                fontSize = 24.sp,
                maxLines = 1,
                textAlign = TextAlign.Center,
                color = defaultColor,
            )
            Text(
                displayMessage,
                maxLines = 2,
                fontSize = 14.sp,
                lineHeight = 20.sp,
                textAlign = TextAlign.Center,
                color = Color.Black,
            )
        }
    }
}