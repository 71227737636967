package ui.eReceipt

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.semantics.clearAndSetSemantics
import androidx.compose.ui.semantics.contentDescription
import androidx.compose.ui.unit.dp
import receipt.Receipt
import toContentDescription
import ui.Colors

@Composable
fun OrderInformation(eReceipt: Receipt) {
    val orderNumber = "Order #: ${eReceipt.transactionDetails["Order ID"]?.lowercase()}"
    val receiptNumber = "Receipt #: ${eReceipt.orderPaymentGuid.takeLast(12)}"
    val date = eReceipt.transactionDetails.getOrElse("Date", { "" })
    val time = eReceipt.transactionDetails.getOrElse("Time", { "" })
    val dateAndTime = "Date: $date $time"
    Card(
        border = BorderStroke(1.dp, Colors.Secondary),
        modifier = Modifier.fillMaxWidth()
            .padding(8.dp)
            .clearAndSetSemantics {
                contentDescription = toContentDescription(
                    orderNumber,
                    dateAndTime
                )
            },
        colors = CardDefaults.cardColors(
            containerColor = Color.White
        )
    ) {
        ReceiptHeaderInformation(
            orderNumber,
            receiptNumber,
            dateAndTime
        )
    }
}