package ui.eReceipt

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.widthIn
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import ui.Colors

@Composable
fun DifferentCustomerBanner(maxWidth: Dp) {
    Box(
        modifier = Modifier
            .background(Colors.alertBanner)
            .widthIn(0.dp, maxWidth)
            .padding(vertical = 8.dp)
    ) {
        Text(
            "This receipt is not attached to your account",
            modifier = Modifier.fillMaxWidth(),
            textAlign = TextAlign.Center,
            color = Color.White
        )
    }
}