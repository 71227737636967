package model

import linx.linxapp.generated.resources.Res
import linx.linxapp.generated.resources.american_express_svgrepo_com
import linx.linxapp.generated.resources.mastercard_light
import linx.linxapp.generated.resources.visa_color
import org.jetbrains.compose.resources.DrawableResource

enum class CardType(
    initialDigit: String,
    val drawableResource: DrawableResource,
    val displayName: String
) {
    AMEX("3", Res.drawable.american_express_svgrepo_com, "American Express"),
    VISA("4", Res.drawable.visa_color, "Visa"),
    MasterCard("5", Res.drawable.mastercard_light, "MasterCard"),
    Discover("6", Res.drawable.visa_color, "Discover"),
    Unknown("7", Res.drawable.visa_color, "Unknown");
    companion object {
        private fun fromString(digit: String?): CardType {
            return when (digit) {
                "3" -> AMEX
                "4" -> VISA
                "5" -> MasterCard
                "6" -> Discover
                else -> Unknown
            }
        }

        fun getDrawable(digit: String?): DrawableResource {
            val cardType = CardType.fromString(digit?.substring(0, 1))
            return cardType.drawableResource
        }

        fun getCardType(digit: String?): String {
            val cardType = CardType.fromString(digit?.substring(0, 1))
            return cardType.displayName
        }
    }
}