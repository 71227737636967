package ui.eReceipt

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.animation.expandVertically
import androidx.compose.animation.fadeIn
import androidx.compose.animation.fadeOut
import androidx.compose.animation.shrinkVertically
import androidx.compose.animation.slideInVertically
import androidx.compose.animation.slideOutVertically
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.widthIn
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material.Icon
import androidx.compose.material.Text
import androidx.compose.material.ripple.rememberRipple
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.rotate
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.platform.testTag
import androidx.compose.ui.semantics.Role
import androidx.compose.ui.semantics.clearAndSetSemantics
import androidx.compose.ui.semantics.contentDescription
import androidx.compose.ui.semantics.role
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import kotlinx.coroutines.flow.StateFlow
import linx.linxapp.generated.resources.Res
import linx.linxapp.generated.resources.thumb_nail_up
import org.jetbrains.compose.resources.painterResource
import receipt.Question
import receipt.QuestionTypes
import receipt.hasFlag
import ui.AppViewModel


@Composable
fun QuestionSection(
    questionStateFlow: StateFlow<Question?>,
    onScore: (Int, Int) -> Unit,
    maxWidth: Dp,
) {
    val _question by questionStateFlow.collectAsState()
    val question = _question

    var shownQuestion: Question? by remember { mutableStateOf(null) }
    AnimatedVisibility(
        visible = question != null,
        enter = slideInVertically() + expandVertically() + fadeIn(),
        exit = slideOutVertically() + shrinkVertically() + fadeOut()
    ) {
        if (question != null) shownQuestion = question
        shownQuestion?.let {
            val ratingType = if (it.questionType.hasFlag(QuestionTypes.Thumb) && it.display != null) {
                AppViewModel.RatingType.Thumb(it.display!!)
            } else {
                AppViewModel.RatingType.None
            }
            QuestionView(
                ratingType = ratingType,
                onThumbsUp = { if (question != null) { onScore(it.questionId, 5) } },
                onThumbsDown = { if (question != null) { onScore(it.questionId, 1) } },
                maxWidth = maxWidth
            )
        }
    }
}

@Composable
fun QuestionView(
    ratingType: AppViewModel.RatingType,
    onThumbsUp: () -> Unit,
    onThumbsDown: () -> Unit,
    modifier: Modifier = Modifier,
    maxWidth: Dp,
) {
    Column(
        modifier = modifier
            .widthIn(0.dp, maxWidth)
            .padding(top = 16.dp, bottom = 48.dp)
        ,
        horizontalAlignment = Alignment.CenterHorizontally,
    ) {
        Text(
            modifier = Modifier
                .testTag("QuestionTitle")
            ,
            text = ratingType.title,
            maxLines = 2,
            fontSize = 20.sp,
            fontStyle = FontStyle.Italic,
        )
        Row(
            modifier = Modifier
                .fillMaxWidth()
                .padding(top = 16.dp)
            ,
            horizontalArrangement = Arrangement.SpaceEvenly,
        ) {
            Icon(
                modifier = Modifier
                    .size(64.dp)
                    .clearAndSetSemantics {
                        contentDescription = "Thumbs Up"
                        role = Role.Button
                    }
                    .background(Color.White, shape = CircleShape)
                    .padding(16.dp)
                    .clickable(
                        interactionSource = remember { MutableInteractionSource() }, // without this indication parameter gets an error
                        indication = rememberRipple(
                            bounded = false,
                            radius = 32.dp,
                        ),
                        onClick = onThumbsUp
                    )
                    .testTag("ThumbRatingUp")
                ,
                painter = painterResource(Res.drawable.thumb_nail_up),
                contentDescription = null,
                tint = Color(0xFF79B74C),
            )
            Icon(
                modifier = Modifier
                    .size(64.dp)
                    .clearAndSetSemantics {
                        contentDescription = "Thumbs Down"
                        role = Role.Button
                    }
                    .background(Color.White, shape = CircleShape)
                    .padding(16.dp)
                    .rotate(180f)
                    .clickable(
                        interactionSource = remember { MutableInteractionSource() }, // without this indication parameter gets an error
                        indication = rememberRipple(
                            bounded = false,
                            radius = 32.dp,
                        ),
                        onClick = onThumbsDown
                    )
                    .testTag("ThumbRatingDown")
                ,
                painter = painterResource(Res.drawable.thumb_nail_up),
                contentDescription = null,
                tint = Color(0xFFFF6565),
            )
        }
    }
}