package ui.eReceipt

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.widthIn
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.text.selection.SelectionContainer
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.CircularProgressIndicator
import androidx.compose.material.Divider
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.semantics.clearAndSetSemantics
import androidx.compose.ui.semantics.contentDescription
import androidx.compose.ui.unit.dp
import kotlinx.coroutines.flow.StateFlow
import receipt.Receipt
import ui.Colors.Companion.SimpleEreceiptBackground

@Composable
fun SimplifiedEReceiptScreen(
    eReceiptStateFlow: StateFlow<Receipt?>,
    showAppFullScreen: Boolean,
) {
    val scrollState = rememberScrollState()
    val maxWidth = eReceiptMaxWidth
    SelectionContainer {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .fillMaxHeight()
                .background(SimpleEreceiptBackground)
                .padding(top = 8.dp)
                .verticalScroll(scrollState),
            horizontalAlignment = Alignment.CenterHorizontally,
        ) {
            val _eReceipt by eReceiptStateFlow.collectAsState()
            val eReceipt = _eReceipt

            if (eReceipt != null) {
                PerforatedEdge(
                    maxWidth = maxWidth
                )
                Divider(
                    modifier = Modifier
                        .height(16.dp)
                        .widthIn(0.dp, maxWidth),
                    color = Color.White
                )
                Box(
                    modifier = Modifier
                        .widthIn(eReceiptMaxWidth, maxWidth)
                        .background(Color.White)
                ) {
                    OrderInformation(eReceipt)
                }
                Divider(
                    modifier = Modifier
                        .height(4.dp)
                        .widthIn(0.dp, maxWidth),
                    color = Color.White
                )
                ReceiptContent(eReceipt, maxWidth)
                eReceipt.transactionDetails.filter { it.key != "Order ID" && it.key != "Date" && it.key != "Time" }.let {
                    Divider(
                        modifier = Modifier
                            .height(16.dp)
                            .widthIn(0.dp, maxWidth),
                        color = Color.White
                    )
                    TransactionInformation(it, maxWidth)
                }
                Divider(
                    modifier = Modifier
                        .height(16.dp)
                        .widthIn(0.dp, maxWidth),
                    color = Color.White
                )
                PerforatedEdge(
                    isTopPosition = false,
                    maxWidth = maxWidth
                )
            } else {
                CircularProgressIndicator(
                    modifier = Modifier
                        .align(Alignment.CenterHorizontally)
                        .clearAndSetSemantics {
                            contentDescription = "Loading eReceipt"
                        }
                )
            }
        }
    }
}