package ui

import ConfigFile
import Flavor
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import globalCoroutineExceptionHandler
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.flow
import kotlinx.coroutines.launch
import model.Card
import model.ConsumerInformationV2
import model.DataRepository
import model.MerchantFullInformationV2
import model.MerchantSummaryInformation
import navigation.NavigationState
import receipt.Question
import receipt.Receipt
import ui.snackbars.SnackBarState

class AppViewModel(
    private val flavor: Flavor,
    val clientConfig: ConfigFile,
    private val dataRepository: DataRepository,
) : ViewModel() {

    val clientName = flavor.clientName

    private val dialogStateMutableStateFlow = MutableStateFlow<DialogState>(DialogState.Nothing())
    val dialogStateStateFlow = dialogStateMutableStateFlow

    val eReceiptStateFlow: StateFlow<Receipt?> = dataRepository.eReceiptStateFlow
    val merchantInformationStateFlow: StateFlow<MerchantFullInformationV2?> = dataRepository.merchantInformationStateFlow
    val consumerInformationStateFlow: StateFlow<ConsumerInformationV2?> = dataRepository.consumerInformationStateFlow
    val paymentsStateFlow: StateFlow<DataRepository.PaymentState> = dataRepository.paymentsStateFlow
    val cardsStateFlow: StateFlow<List<Card?>?> = dataRepository.cardsStateFlow
    val navigationStateFlow: StateFlow<NavigationState> = dataRepository.navigationStateFlow
    val backStackStateFlow: StateFlow<List<NavigationState>> = dataRepository.backStackStateFlow
    val snackbarStateStateFlow: StateFlow<SnackBarState> = dataRepository.snackbarStateStateFlow
    val orderQuestionStateFlow: StateFlow<Question?> = dataRepository.orderQuestionStateFlow

    val showAppFullScreen = dataRepository.showAppFullScreen

    sealed class DialogState() {
        class Nothing() : DialogState()
        class LogOutConfirmation(val onConfirm: (Boolean) -> Unit) : DialogState()
    }

    sealed class RatingType(val title: String) {
        class Thumb(title: String) : RatingType(title)
        object None : RatingType("")
    }

    fun setMerchant(merchant: MerchantFullInformationV2) {
        dataRepository.setMerchant(merchant)
    }

    fun loadPaymentsAsync() {
        dataRepository.loadPaymentsAsync(
            paymentsStateFlow.value.payments,
            merchantInformationStateFlow.value!!.consumerLoyaltyProgramGuid!!
        )
    }

    fun setEreceipt(orderPaymentGuid: String) {
        dataRepository.onTransactionClicked(orderPaymentGuid)
    }

    fun hashStateChangeEvent(newHash: String, locationListener: (String) -> Unit) {
        dataRepository.hashStateChangeEvent(newHash, locationListener)
    }

    fun popBackStack() {
        dataRepository.popBackStack()
    }

    fun getToken(): String? {
        return dataRepository.getToken()
    }

    fun isTokenAvailable(): Boolean {
        return !dataRepository.getToken().isNullOrEmpty()
    }

    fun requestLogOut() {
        dialogStateMutableStateFlow.value = DialogState.LogOutConfirmation {
            dialogStateMutableStateFlow.value = DialogState.Nothing()
            if (it) {
                dataRepository.logOut()
            }
        }
    }

    fun openSearchScreen() {
        dataRepository.navigate(NavigationState.Search())
    }

    fun setTermsOfService() {
        dataRepository.navigate(NavigationState.TermsOfService())
    }

    fun setPrivacyPolicy() {
        dataRepository.navigate(NavigationState.PrivacyPolicy())
    }

    fun requestAuthenticationFromOrderPaymentGuid(orderPaymentGuid: String) {
        viewModelScope.launch(globalCoroutineExceptionHandler + Dispatchers.Default) {
            dataRepository.requestAuthenticationFromOrderPaymentGuid(orderPaymentGuid)
            dataRepository.setSnackbarState(SnackBarState.PhoneNumberAuthRequested())
        }
    }

    fun setSubmitted() {
        dataRepository.navigate(NavigationState.LoginSubmitted())
    }

    fun cancelEnrollment() = Unit

    private fun getMerchantsNearMe(): Flow<List<MerchantSummaryInformation>?> {
        return flow { emit(dataRepository.getMerchantsNearMe()) }
    }

    fun answerQuestion(orderPaymentGuid: String, questionId: Int, score: Int) {
        dataRepository.answerQuestion(orderPaymentGuid, questionId, score)
    }

    private val _searchText = MutableStateFlow("")
    val searchText = _searchText

    val filteredMerchantList = searchText
        .combine(getMerchantsNearMe()) { searchText, listOfMerchantSummary ->
            if (searchText.isBlank()) {
                return@combine listOfMerchantSummary
            }
            listOfMerchantSummary?.filter { merchant ->
                merchant.name!!.lowercase().contains(searchText.trim().lowercase())
            }
        }

    fun onSearchTextChange(text: String) {
        _searchText.value = text
    }
}