package ui.search

import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material.Text
import androidx.compose.material3.OutlinedTextField
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.input.key.Key
import androidx.compose.ui.input.key.KeyEventType
import androidx.compose.ui.input.key.key
import androidx.compose.ui.input.key.onPreviewKeyEvent
import androidx.compose.ui.input.key.type
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.unit.dp
import linx.linxapp.generated.resources.Res
import linx.linxapp.generated.resources.merchants_near_me
import org.jetbrains.compose.resources.stringResource

@Composable
fun SearchTextField(searchText: String, onSearchTextChange: (String) -> Unit) {
    OutlinedTextField(
        value = searchText,
        onValueChange = onSearchTextChange,
        label = {
            Text(text = stringResource(Res.string.merchants_near_me))
        },
        keyboardOptions = KeyboardOptions(keyboardType = KeyboardType.Text),
        shape = RoundedCornerShape(0.dp),
        modifier = Modifier.onPreviewKeyEvent {
            when {
                (it.key == Key.Enter && it.type == KeyEventType.KeyUp) -> {
                    true
                }

                else -> {
                    false
                }
            }
        },
    )
}