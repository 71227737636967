import androidx.compose.ui.Modifier
import androidx.compose.ui.semantics.clearAndSetSemantics
import kotlinx.coroutines.CoroutineExceptionHandler
import kotlinx.datetime.LocalDate
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.format
import kotlinx.datetime.format.byUnicodePattern


val globalCoroutineExceptionHandler = CoroutineExceptionHandler { _, exception ->
    println("Exception caught: $exception")
}

fun Long.toPhoneNumberFormat(): String {
    val longAsString = this.toString()
    return longAsString.toPhoneNumberFormat()
}

fun Long.toSingleCharacterContentDescription(): String {
    val longAsString = this.toString()
    return longAsString.toSingleCharacterContentDescription()
}

fun String.toPhoneNumberFormat(): String {
    val newNumber = when (this.length) {
        10 -> this
        11 -> this.drop(1)
        else -> return ""
    }

    val string = StringBuilder("(")
        .append(newNumber.take(3))
        .append(")")
        .append("-")
        .append(newNumber.drop(3).take(3))
        .append("-")
        .append(newNumber.drop(6))

    return string.toString()
}

fun String.toSingleCharacterContentDescription(): String {
    val newNumber = this
    val string = buildString {
        newNumber.forEach {
            append(it)
            append(" ")
        }
    }
    println(string)
    return string
}

fun LocalDateTime.toDateHourFormat(): Pair<String, String> {

    val month = this.date.month.name.formatMonth()
    val displayDate: String =
        month + " " + this.date.format(LocalDate.Format { byUnicodePattern("dd, yyyy") })

    val hour = this.hour
    val (formattedHour, period) = hour.takeIf { it < 13 }?.let { it to "AM" } ?: ((hour - 12) to "PM")
    val formattedMinutes = this.minute.formatMinutes()
    val formattedTime = "$formattedHour:$formattedMinutes $period"

    return Pair(displayDate, formattedTime)
}

fun LocalDateTime.toDateFormat(): String {

    val hour = this.hour
    val (formattedHour, period) = hour.takeIf { it < 13 }?.let { it to "AM" } ?: ((hour - 12) to "PM")
    val formattedMinutes = this.minute.formatMinutes()
    val displayTime = "$formattedHour:$formattedMinutes $period"

    val displayDate = this.format(LocalDateTime.Format {
        byUnicodePattern("MM/dd/yy ")
    }) + displayTime

    return displayDate
}

fun Int.formatMinutes(): String {
    return this.toString().padStart(2, '0')
}

fun String.formatMonth(): String {
    return this.first().uppercase() + this.drop(1).lowercase()
}

infix fun <T : Comparable<T>> T?.nullsBiggerThan(other: T?): Boolean = when {
    this == null -> false
    other == null -> false
    else -> this > other
}

fun String.stripNonNumbers(): String {
    val re = Regex("[^0-9]")
    return re.replace(this, "")
}

fun String.removeDecimalPart():String {
    return this.split(".")[0]
}

fun String.removeTrailingZerosAndTruncateToTwoDecimalPositionsIfAny(): String {
    val integerPart = this.split(".")[0]
    val decimalPart = this.split(".")[1]

    val string = buildString {
        append(integerPart)
        if (decimalPart.isNotEmpty()) {
            val temp = decimalPart.replace(Regex("0*$"), "").replace(Regex("\\.$"), "")
            if (temp.isNotEmpty()) {
                append(".")
                append(
                   temp
                )
            }
        }
    }
    return string
}

fun String.formatToPercentage():String{
    return this.removeTrailingZerosAndTruncateToTwoDecimalPositionsIfAny() + "%"
}

fun toContentDescription(vararg strings: String): String {
    return buildString {
        strings.forEach {
            append(it)
            append("\n")
        }
    }
}

fun Modifier.clearSemantics() = this.then(
    Modifier.clearAndSetSemantics {}
)