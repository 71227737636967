package navigation

import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.filter
import kotlinx.coroutines.flow.filterNotNull
import kotlinx.coroutines.flow.take
import kotlinx.coroutines.flow.timeout
import kotlinx.coroutines.launch
import model.DataRepository
import kotlin.time.Duration.Companion.minutes
import kotlin.time.Duration.Companion.seconds

class Navigation(
    private val scope: CoroutineScope,
    private val dataRepository: DataRepository,
) {

    private val navigationStateMutableStateFlow: MutableStateFlow<NavigationState> = MutableStateFlow(NavigationState.Blank())
    val navigationStateFlow = navigationStateMutableStateFlow.asStateFlow()

    private val backStackMutableStateFlow = MutableStateFlow<List<NavigationState>>(emptyList())
    val backStackStateFlow = backStackMutableStateFlow.asStateFlow()

    val token = dataRepository.getToken()

    fun navigate(navigationState: NavigationState) {
        when (navigationState) {
            is NavigationState.Blank -> Unit

            is NavigationState.Login -> {
                backStackMutableStateFlow.value = emptyList()
            }

            is NavigationState.LoginSubmitted -> Unit

            is NavigationState.Auth -> Unit

            is NavigationState.AuthFailed -> Unit

            is NavigationState.Exit -> Unit

            is NavigationState.ConsumerInformation -> {
                backStackMutableStateFlow.value = listOf(navigationState)
            }

            is NavigationState.MerchantInformation -> {
                val newBackStack = listOf(NavigationState.ConsumerInformation())
                backStackMutableStateFlow.value = newBackStack + navigationState
            }

            is NavigationState.TermsOfService -> {
                if (dataRepository.getToken() != null) {
                    val newBackStack = if (navigationStateFlow.value is NavigationState.EReceipt) {
                        backStackMutableStateFlow.value
                    } else {
                        if (dataRepository.consumerInformationStateFlow.value == null) {
                            scope.launch {
                                dataRepository.setConsumerInformationFromToken { isSet ->
                                    if (!isSet) {
                                        backStackMutableStateFlow.value = listOf(navigationState)
                                    }
                                }
                            }
                        }
                        listOf(NavigationState.ConsumerInformation())
                    }
                    backStackMutableStateFlow.value = newBackStack + navigationState
                }
            }

            is NavigationState.PrivacyPolicy -> {
                if (dataRepository.getToken() != null) {
                    val newBackStack = if (navigationStateFlow.value is NavigationState.EReceipt) {
                        backStackMutableStateFlow.value
                    } else {
                        if (dataRepository.consumerInformationStateFlow.value == null) {
                            scope.launch {
                                dataRepository.setConsumerInformationFromToken { isSet ->
                                    if (!isSet) {
                                        backStackMutableStateFlow.value = listOf(navigationState)
                                    }
                                }
                            }
                        }
                        listOf(NavigationState.ConsumerInformation())
                    }
                    backStackMutableStateFlow.value = newBackStack + navigationState
                }
            }

            is NavigationState.EReceipt -> {
                val newBackStack = if (dataRepository.getToken() == null) {
                    emptyList()
                } else if (navigationStateFlow.value is NavigationState.MerchantInformation) {
                    scope.launch {
                        dataRepository.eReceiptStateFlow
                            .filterNotNull()
                            .filter { it.orderPaymentGuid == navigationState.guid }
                            .take(1)
                            .timeout(1.minutes)
                            .collect { eReceipt ->
                                if (eReceipt.questionAnswered != true) {
                                    dataRepository.loadQuestionAsync(eReceipt.orderPaymentGuid)
                                }
                            }
                    }
                    backStackStateFlow.value
                } else {
                    scope.launch {
                        if (dataRepository.consumerInformationStateFlow.value == null) {
                            launch {
                                dataRepository.setConsumerInformationFromToken { isSet ->
                                    if (!isSet) {
                                        backStackMutableStateFlow.value = listOf(navigationState)
                                    }
                                }
                            }
                        }
                        dataRepository.eReceiptStateFlow
                            .filterNotNull()
                            .filter { it.orderPaymentGuid == navigationState.guid }
                            .take(1)
                            .timeout(1.minutes)
                            .collect { eReceipt ->
                                eReceipt.consumerLoyaltyProgramGuid?.let { consumerLoyaltyProgramGuid ->
                                    launch {
                                        dataRepository.consumerInformationStateFlow
                                            .filterNotNull()
                                            .take(1)
                                            .timeout(1.minutes)
                                            .collect { consumerInformation ->
                                                val merchantInfo =
                                                    consumerInformation.merchants.firstOrNull { it?.consumerLoyaltyProgramGuid == consumerLoyaltyProgramGuid }
                                                if (merchantInfo != null) {
                                                    dataRepository.setMerchantInformation(merchantInfo)
                                                    launch { dataRepository.loadPaymentsAsync(emptyList(), consumerLoyaltyProgramGuid) }
                                                    if (navigationStateFlow.value == navigationState) {
                                                        val merchantInformation =
                                                            NavigationState.MerchantInformation(consumerLoyaltyProgramGuid)
                                                        backStackMutableStateFlow.value = listOf(
                                                            NavigationState.ConsumerInformation(),
                                                            merchantInformation,
                                                            navigationState
                                                        )
                                                    }
                                                }
                                            }
                                    }
                                }
                                if (eReceipt.questionAnswered != true) {
                                    dataRepository.loadQuestionAsync(eReceipt.orderPaymentGuid)
                                }
                            }
                    }
                    listOf(NavigationState.ConsumerInformation())
                }
                backStackMutableStateFlow.value = newBackStack + navigationState
            }

            is NavigationState.Search -> {
                val newBackStack = listOf(NavigationState.ConsumerInformation())
                backStackMutableStateFlow.value = newBackStack + navigationState
            }

            is NavigationState.SEReceipt -> {
                backStackMutableStateFlow.value = emptyList()
            }

        }
        navigationStateMutableStateFlow.value = navigationState
    }

    fun popBackStack() {
        backStackStateFlow.value.let {
            if (it.size > 1) {
                navigate(it[it.size - 2])
            } else {
                navigate(NavigationState.Exit())
            }
        }
    }

    fun hashStateChangeEvent(newHash: String, locationListener: (String) -> Unit) {
        val navigationState = NavigationState.fromHash(newHash)
        if (navigationState == null) {
            if (backStackStateFlow.value.isEmpty()) {
                navigationStateMutableStateFlow.value = NavigationState.Login()
            }
            locationListener(navigationStateMutableStateFlow.value.asUrlString())
            return
        }

        val currentState = backStackStateFlow.value.lastOrNull()
        if (navigationState != currentState) {
            when (navigationState) {
                is NavigationState.Login -> {
                    if (dataRepository.getToken() != null) {
                        displayConsumerInformation()
                    } else {
                        navigate(NavigationState.Login())
                        locationListener(NavigationState.Login().asUrlString())
                    }
                }

                is NavigationState.Auth -> {
                    navigationStateMutableStateFlow.value = navigationState
                    authenticateGuid(navigationState.guid)
                }

                is NavigationState.ConsumerInformation -> {
                    if (dataRepository.getToken() != null) {
                        displayConsumerInformation()
                    } else {
                        navigate(NavigationState.Login())
                        locationListener(NavigationState.Login().asUrlString())
                    }
                }

                is NavigationState.MerchantInformation -> {
                    if (dataRepository.getToken() != null) {
                        scope.launch {
                            if (dataRepository.consumerInformationStateFlow.value == null) {
                                dataRepository.setConsumerInformationFromToken { isSet ->
                                    if (!isSet) {
                                        navigate(NavigationState.Login())
                                        locationListener(NavigationState.Login().asUrlString())
                                    }
                                }
                            }
                            val consumerInformation = dataRepository.consumerInformationStateFlow.value!!
                            val merchant = consumerInformation.merchants.first { it!!.consumerLoyaltyProgramGuid == navigationState.guid }!!
                            dataRepository.setMerchantInformation(merchant)
                            dataRepository.loadPaymentsAsync(emptyList(), merchant.consumerLoyaltyProgramGuid!!)
                        }
                        navigate(NavigationState.MerchantInformation(navigationState.guid))
                    } else {
                        navigate(NavigationState.Login())
                        locationListener(NavigationState.Login().asUrlString())
                    }
                }

                is NavigationState.EReceipt -> {
                    if (dataRepository.eReceiptStateFlow.value?.orderPaymentGuid != navigationState.guid) {
                        dataRepository.loadEreceiptAsync(navigationState.guid)
                    }
                    navigate(NavigationState.EReceipt(navigationState.guid))
                }

                is NavigationState.TermsOfService -> {
                    navigate(NavigationState.TermsOfService())
                }

                is NavigationState.PrivacyPolicy -> {
                    navigate(NavigationState.PrivacyPolicy())
                }

                is NavigationState.Search -> {
                    navigate(NavigationState.Search())
                }

                is NavigationState.SEReceipt -> {
                    dataRepository.loadSimplifiedEreceiptAsync(navigationState.guid)
                    navigate(NavigationState.SEReceipt(navigationState.guid))
                }

                else -> Unit
            }
        }
    }

    fun displayConsumerInformation() {
        dataRepository.setConsumerInformation(null)
        scope.launch {
            dataRepository.setConsumerInformationFromToken { isSet ->
                if (isSet) {
                    navigate(NavigationState.ConsumerInformation())
                } else {
                    navigate(NavigationState.Login())
                }
            }
        }
    }

    private fun authenticateGuid(guid: String) {
        scope.launch {
            val consumerToken = dataRepository.authenticateGuid(guid)
            if (consumerToken.isNotEmpty()) {
                dataRepository.setToken(consumerToken)
                displayConsumerInformation()
            } else {
                if (dataRepository.getToken() != null) {
                    navigationStateMutableStateFlow.value = NavigationState.ConsumerInformation()
                } else {
                    navigationStateMutableStateFlow.value = NavigationState.AuthFailed()
                    delay(5.seconds)
                    navigate(NavigationState.Login())
                }
            }
        }
    }
}