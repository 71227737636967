package ui.merchantInformation

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.material.ExperimentalMaterialApi
import androidx.compose.material.Text
import androidx.compose.material.pullrefresh.PullRefreshIndicator
import androidx.compose.material.pullrefresh.pullRefresh
import androidx.compose.material.pullrefresh.rememberPullRefreshState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import kotlinx.coroutines.flow.StateFlow
import model.Card
import model.DataRepository
import model.MerchantFullInformationV2
import ui.Colors
import ui.scrollbar.LazyColumnScrollbar

@OptIn(ExperimentalMaterialApi::class)
@Composable
fun MerchantInformationScreen(
    onTransactionClicked: (orderPaymentGuid: String) -> Unit,
    merchantInformationStateFlow: StateFlow<MerchantFullInformationV2?>,
    paymentStateStateFlow: StateFlow<DataRepository.PaymentState>,
    cardsStateFlow: StateFlow<List<Card?>?>,
    shouldShowScrollbar: Boolean,
    onPullToRefresh: () -> Unit,
    merchantCardView: @Composable (merchantInformationStateFlow: StateFlow<MerchantFullInformationV2?>, (MerchantFullInformationV2) -> Unit) -> Unit,
) {
    Box(
        modifier = Modifier
            .height(100.dp)
            .fillMaxWidth()
            .background(Colors.Primary)
    )
    Column(
        horizontalAlignment = Alignment.CenterHorizontally,
    ) {
        merchantCardView(merchantInformationStateFlow) {}
        Text(
            "Transactions",
            fontWeight = FontWeight.Bold,
            modifier = Modifier
                .align(Alignment.CenterHorizontally)
                .padding(start = 16.dp, top = 16.dp, bottom = 16.dp)
        )

        Box(modifier = Modifier.fillMaxWidth()) {
            val paymentState by paymentStateStateFlow.collectAsState()
            val lazyListState = rememberLazyListState()

            val pullRefreshState = rememberPullRefreshState(
                refreshing = paymentState is DataRepository.PaymentState.Refreshing,
                onRefresh = onPullToRefresh
            )
            Column {
                LazyColumnScrollbar(
                    listState = lazyListState,
                    padding = 4.dp,
                    alwaysShowScrollBar = shouldShowScrollbar
                ){
                    LazyColumn(
                        state = lazyListState,
                        modifier = Modifier
                            .pullRefresh(pullRefreshState)
                            .padding(horizontal = 16.dp)
                        ,
                    ) {
                        items(paymentState.payments) {
                            TransactionView(it, onTransactionClicked, cardsStateFlow)
                        }
                    }
                }
            }
            PullRefreshIndicator(
                refreshing = paymentState is DataRepository.PaymentState.Refreshing,
                state = pullRefreshState,
                modifier = Modifier.align(Alignment.TopCenter),
            )
        }

    }
}