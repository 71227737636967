package ui.eReceipt

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.widthIn
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.semantics.clearAndSetSemantics
import androidx.compose.ui.semantics.contentDescription
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import toContentDescription
import ui.Colors.Companion.TransactionBorderStroke

@Composable
fun TransactionInformation(
    additionalInformation: Map<String, String>,
    maxWidth: Dp,
) {
    Box(
        modifier = Modifier
            .widthIn(0.dp, maxWidth)
            .background(Color.White)
    ) {
        Column(
            modifier = Modifier.fillMaxWidth()
        ) {
            additionalInformation.forEach {
                SpacedTextDisplay(
                    it.key,
                    it.value,
                    color = TransactionBorderStroke,
                    modifier = Modifier.padding(horizontal = 20.dp, vertical = 0.dp).clearAndSetSemantics {
                        contentDescription = toContentDescription(it.key, it.value)
                    }
                )
            }
        }
    }
}