package ui.merchantInformation

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.material.Card
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.semantics.clearAndSetSemantics
import androidx.compose.ui.semantics.contentDescription
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import formatDecimalToCurrency
import kotlinx.coroutines.flow.StateFlow
import model.Card
import model.CardType
import model.OrderPayment
import org.jetbrains.compose.resources.painterResource
import toContentDescription
import toDateHourFormat
import ui.Colors

@Composable
fun TransactionView(orderPayment: OrderPayment, onTransactionClicked: (String) -> Unit, cardsStateFlow: StateFlow<List<Card?>?>) {
    val cards by cardsStateFlow.collectAsState()
    val dateAndTime = orderPayment.orderStartTime.toDateHourFormat()
    val date = dateAndTime.first
    val time = dateAndTime.second
    val amount = orderPayment.amountTotal?.formatDecimalToCurrency()
    Card(
        elevation = 0.dp,
        modifier = Modifier
            .fillMaxWidth()
            .padding(vertical = 4.dp)
            .clickable {
                onTransactionClicked.invoke(orderPayment.orderPaymentGuid ?: "")
            }
            .clearAndSetSemantics {
                contentDescription = toContentDescription(
                    "Transaction from $date",
                    "at $time",
                    "for $amount"
                )
            }
    ) {
        Row(
            horizontalArrangement = Arrangement.SpaceEvenly,
            modifier = Modifier
                .background(Colors.Default)
                .fillMaxWidth()
                .padding(horizontal = 8.dp, vertical = 4.dp)
        ) {

            val cardIdentifier: String = cards?.firstOrNull {
                it?.cardId == orderPayment.cardId
            }?.panMasked ?: "-1"

            Image(
                painter = painterResource(CardType.getDrawable(cardIdentifier)),
                contentDescription = null,
                modifier = Modifier
                    .padding(8.dp)
                    .size(40.dp)
            )
            Column(modifier = Modifier.weight(1f)) {
                Text(date, fontWeight = FontWeight.Medium)
                Text(time)
            }
            Text(
                "$amount",
                fontWeight = FontWeight.Bold
            )
        }
    }
}