package ui.eReceipt

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.widthIn
import androidx.compose.material.Divider
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.semantics.clearAndSetSemantics
import androidx.compose.ui.semantics.contentDescription
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import formatDecimalToCurrency
import receipt.Receipt
import toContentDescription
import ui.Colors

@Composable
fun ReceiptContent(
    eReceipt: Receipt,
    maxWidth: Dp,
) {
    Column(
        modifier = Modifier
            .background(Color.White)
            .widthIn(0.dp, maxWidth)
    ) {
        val paymentType = eReceipt.paymentType
        paymentType?.run {
            Text(
                modifier = Modifier.fillMaxWidth(),
                text = this,
                textAlign = TextAlign.Center,
            )
        }
        Spacer(modifier = Modifier.height(12.dp))

        eReceipt.paymentDetails.forEach {
            if (it.key == "Total") {
                Divider(
                    modifier = Modifier
                        .height(1.dp)
                        .padding(horizontal = 16.dp),
                    color = Color.Black
                )
            }
            SpacedTextDisplay(
                label = it.key,
                amount = it.value.formatDecimalToCurrency(),
                Modifier.padding(horizontal = 20.dp, vertical = 2.dp)
                    .clearAndSetSemantics {
                        contentDescription = toContentDescription(
                            it.key,
                            it.value.formatDecimalToCurrency()
                        )
                    },
                color = if (it.key == "Reward") {
                    Colors.Secondary
                } else {
                    Color.Black
                },
                fontWeight = if (it.key == "Total") {
                    FontWeight.Bold
                } else {
                    FontWeight.Normal
                },
            )
        }
    }
}