package ui.search

import androidx.compose.foundation.LocalIndication
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.material.Text
import androidx.compose.material3.ElevatedCard
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.compose.ui.semantics.clearAndSetSemantics
import androidx.compose.ui.semantics.contentDescription
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import model.MerchantSummaryInformation
import toContentDescription
import toPhoneNumberFormat
import toSingleCharacterContentDescription
import ui.Colors

@Composable
fun MerchantNearMeCard(
    store: MerchantSummaryInformation,
    onMerchantClicked: (MerchantSummaryInformation) -> Unit = {},
    shouldDeactivateClickEffects: Boolean = false,
) {
    val storeName = store.name
    val storeAddress = store.address
    val storePhoneNumber = store.phoneNumber

    ElevatedCard(
        modifier = Modifier
            .fillMaxWidth()
            .height(116.dp)
            .padding(horizontal = 16.dp)
            .clickable(
                interactionSource = remember { MutableInteractionSource() },
                indication = if (!shouldDeactivateClickEffects) LocalIndication.current else null,
                enabled = true,
                onClickLabel = null,
            ) {
                onMerchantClicked(store)
            }
    ) {
        Row(
            modifier = Modifier
                .background(Colors.Default)
                .fillMaxSize()
        ) {
            Column(
                verticalArrangement = Arrangement.SpaceEvenly,
                modifier = Modifier.fillMaxWidth()
                    .clearAndSetSemantics {
                        contentDescription = toContentDescription(
                            storeName ?: "",
                            storeAddress ?: "",
                            storePhoneNumber?.toSingleCharacterContentDescription() ?: ""
                        )
                    }
                    .padding(horizontal = 16.dp, vertical = 8.dp)
                    .fillMaxHeight()
                    .weight(1f),
            ) {
                storeName?.let { Text(it, fontWeight = FontWeight.Bold) }
                storeAddress?.let { Text(it, maxLines = 3, overflow = TextOverflow.Ellipsis) }
                storePhoneNumber?.let { Text(it.toPhoneNumberFormat()) }
            }
            if (store.dailyOffer?.receivedType != null) {
                DailyOffer(store)
            }
        }
    }
}